/**
 * Public Views
 * !Public view pages should be imported here and add with public view categories
 */
import React, { Suspense, lazy } from "react";
import GBackdrop from "../components/layouts/feedbacks/GBackdrop";
const Home = lazy(() => import("../components/views/public/Home"));
const Contact = lazy(() => import("../components/views/public/Contact"));
const Product = lazy(() => import("../components/views/public/Product"));
const About = lazy(() => import("../components/views/public/About"));

// layout
const PublicLayout = lazy(() => import("../components/layouts/PublicLayout"));

const routes = [
  /**
   *  Public Views
   *  Directories:
   *  Views -> public - > [Home]
   */
  {
    path: "/",
    element: (
      <Suspense fallback={<GBackdrop />}>
        <PublicLayout />
      </Suspense>
    ),
    children: [
      { index: true, element: <Home /> },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/product",
        element: <Product />,
      },
    ],
  },
];
export default routes;
