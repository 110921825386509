// site theme modes [light, dark];
// Edit/Change your site themes and coloring from this file:
import { createTheme } from "@mui/material/styles";
import { cyan } from "@mui/material/colors";

const defaultTheme = createTheme();
const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // LIGHT MODE
          primary: {
            main: `#e0e0e0`,
            dark: `#1d1e1f`,
            light: `#606060`,
          },
          secondary: {
            main: cyan[600],
            dark: cyan[700],
            light: cyan[500],
          },
          error: {
            main: `#FF6262`,
            dark: `#FF6262`,
            light: `#FF6262`,
          },

          info: {
            main: `#fff`,
            dark: `rgba(255, 255, 255, 0.7)`,
            light: `rgba(0,0,0, 0.3)`,
          },
          text: {
            primary: "#37474f",
            secondary: "#1d1e1f",
          },
          background: {
            default: "#f8f8f8",
            paper: "#ffffff",
          },
        }
      : {
          // DARK MODE
          primary: {
            main: `#e0e0e0`,
            dark: `#1d1e1f`,
            light: `#707070`,
          },
          secondary: {
            main: `#0099CC`,
            dark: `#006b8e`,
            light: `#0099CC`,
          },
          error: {
            main: `#FF6262`,
            dark: `#FF6262`,
            light: `#FF6262`,
          },
          info: {
            main: `#fff`,
            dark: `rgba(255, 255, 255, 0.7)`,
            light: `rgba(0,0,0, 0.3)`,
          },
          text: {
            primary: "#1d1e1f",
            secondary: "#1d1e1f",
          },
          background: {
            default: "#ffffff",
            paper: "#ffffff",
          },
        }),
  },
});

export default getDesignTokens;
