import React from "react";
import { useRoutes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import routes from "./routes/routes";
import { red } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: `#ffffff`,
      dark: `#ffffff`,
      light: `#707070`,
    },
    secondary: {
      main: `#0b1a16`,
      dark: `#0b1a16`,
      light: `#0b1a16`,
    },
    warning: {
      main: red[500],
      dark: red[600],
      light: red[400],
    },
    info: {
      main: `#e0e0e0`,
      dark: `rgba(0, 0, 0, 0.6)`,
      light: `#F9F9F9`,
    },
    text: {
      primary: "#37474f",
      secondary: "#1d1e1f",
    },
  },
});

const App = () => {
  let elements = useRoutes(routes);

  return (
    <>
      <ThemeProvider theme={theme}>{elements}</ThemeProvider>
    </>
  );
};

export default App;
